import { TrackCTAContextProvider } from '@context/analytics/TrackCTAContext';
import Medium from '../../assets/icons/medium.png';
import Pinterest from '../../assets/icons/pinterest.png';
import Twitter from '../../assets/icons/twitter.png';
import Facebook from '../../assets/icons/facebook.png';
import Instagram from '../../assets/icons/instagram.png';
import MakersplaceWhite from '../../assets/icons/makersplace-white.png';
import { DJANGO_URLS } from '../../utils/constants';

export default function Footer() {
  return (
    <div id="site-footer" className="container-fluid footer pt-5 px-md-3 px-lg-5 px-2 pt-md-5 mt-5">
      <div className="pt-md-4">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-6">
            <div className="site-logo" style={{ width: '200px' }}>
              <img alt="mkp" src={MakersplaceWhite} width="200" style={{ marginRight: '3px' }} />
            </div>

            <p className="caption mt-2" style={{ fontSize: '.85rem' }}>
              Create, sell and collect truly rare digital artworks.
              <br />
              Powered by blockchain technology.
            </p>
            <div className="social-icons-box" style={{ paddingTop: '1.5em' }}>
              <a href="https://medium.com/makersplace/" className="mr-3 mr-md-2">
                <img alt="medium" src={Medium} />
              </a>

              <a href="https://pinterest.com/makersplaceco/" className="mr-3 mr-md-2">
                <img alt="pinterest" src={Pinterest} />
              </a>

              <a href="https://instagram.com/makersplace/" className="mr-3 mr-md-2">
                <img alt="instagram" src={Instagram} />
              </a>

              <a href="https://twitter.com/makersplace/" className="mr-3 mr-md-2">
                <img alt="twitter" src={Twitter} />
              </a>

              <a href="https://facebook.com/makersplace/">
                <img alt="facebook" src={Facebook} />
              </a>
            </div>
          </div>

          <div className="offset-md-1 offset-lg-2 col-md-2 pt-5 pt-md-0">
            <div className="h9 mb-3" style={{ fontSize: '.85rem' }}>
              Company
            </div>
            <a href={DJANGO_URLS.WHAT_IS_MAKERSPLACE} style={{ fontSize: '.85rem' }}>
              About
            </a>
            <a href={`${DJANGO_URLS.WHAT_IS_MAKERSPLACE}#values`} style={{ fontSize: '.85rem' }}>
              Mission and Team
            </a>
            <a
              target="_BLANK"
              href="https://rare.makersplace.com"
              rel="noreferrer"
              style={{ fontSize: '.85rem' }}
            >
              Our Blog
            </a>
            <a href={DJANGO_URLS.TERMS} style={{ fontSize: '.85rem' }}>
              Terms of Service
            </a>
            <a
              target="_BLANK"
              href="https://jobs.lever.co/MakersPlace"
              rel="noreferrer"
              style={{ fontSize: '.85rem' }}
            >
              <b>We&apos;re Hiring!</b>
            </a>
          </div>

          <div className="col-md-2 pt-4 pt-md-0">
            <div className="h9 mb-3" style={{ fontSize: '.85rem' }}>
              Help
            </div>
            <TrackCTAContextProvider
              module="concierge_program"
              detail={DJANGO_URLS.CONCIERGE_PROGRAM}
            >
              <a href={DJANGO_URLS.CONCIERGE_PROGRAM} style={{ fontSize: '.85rem' }}>
                Concierge Program
              </a>
            </TrackCTAContextProvider>
            <a href={DJANGO_URLS.ABOUT_CREATORS} style={{ fontSize: '.85rem' }}>
              Sell your Work
            </a>
            <a href={DJANGO_URLS.FAQ} style={{ fontSize: '.85rem' }}>
              FAQ
            </a>
            <a href={`${DJANGO_URLS.FAQ}#support`} style={{ fontSize: '.85rem' }}>
              Support
            </a>
            <a href={DJANGO_URLS.PRIVACY} style={{ fontSize: '.85rem' }}>
              Privacy Policy
            </a>
            <a href={DJANGO_URLS.YOUR_PURCHASES} style={{ fontSize: '.85rem' }}>
              Your Purchases
            </a>
          </div>

          <div className="col-12 pt-4">
            <div className="row legal text-center">
              <div className="col pt-5">
                <span style={{ fontSize: '1em' }}>
                  &copy; 2024 Onchain Labs, Inc. All rights reserved.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
