/** GLOBAL SHARED CONSTANTS */

// Django route constants (be sure to include `/` at end of every path)
export const DJANGO_URLS = {
  ABOUT_CREATORS: '/creators/',
  ABOUT_COLLECTORS: '/collectors/',
  ABOUT_YOU: '/store/edit/about/',
  ACCOUNT_SETTINGS: '/profile/',
  ACTIVITY: '/discover/activity/',
  COLLECTED_ON_SALE: '/store/products/collected/',
  CREATOR_ONBOARD: '/onboard/creator/guide/',
  CREATORS: '/discover/creators/',
  DISCOVER: '/discover/',
  EXPLORE_ARTWORKS: '/explore/artworks',
  DROPS: '/drops/',
  FAQ: '/faq/',
  FAQ_AUTHENTICITY: '/faq/#3rd-party-authentic',
  GENERAL_ABOUT: '/about/',
  HOME: '/',
  HOW_IT_WORKS: '/#whats-makersplace',
  LIVE_OFFERS: '/store/live-offers/',
  LOGIN: '/login/',
  LOGIN_SOCIAL: '/login/social/',
  LOGIN_REDIRECT_TO_EXPLORE_ARTWORKS: '/login/?next=/explore/artworks',
  LOGOUT: '/logout/',
  // TODO: Explore Artwork, direct this out of legacy Marketplace
  MARKETPLACE: '/marketplace/',
  MISSION_AND_TEAM: '/team/',
  POPULAR_COLLECTORS: '/discover/collectors/popular/',
  POPULAR_CREATIONS: '/discover/popular/',
  PRIVACY: '/privacy/',
  PURCHASES_AND_BIDS: '/your/purchases/',
  REGISTER_COLLECTOR: '/register/',
  REGISTER_CREATOR: '/register/creator/',
  SALE_HISTORY: '/store/purchase-history/',
  SALE: '/sale/create/',
  SELL_YOUR_WORK: '/creators/',
  SEND_CREATIONS: '/store/gifts/',
  STORE: '/store/',
  STORE_SETTINGS: '/store/edit/',
  TERMS: '/terms/',
  UPLOAD_CREATION: '/digital-media/create/',
  WHAT_IS_MAKERSPLACE: '/about/',
  YOUR_PURCHASES: '/your/purchases/',
  CONCIERGE_PROGRAM: 'https://rare.makersplace.com/2022/09/20/introducing-makersplace-concierge/',
};

// List of all react apps
export const REACT_URLS = [DJANGO_URLS.PRODUCT_SETTINGS, DJANGO_URLS.HOME];

// User data from Django auth (always comes in as a string. Nonexistent items are '')
export const DJANGO_REQUEST = {
  currentUserId: JSON.parse(document.getElementById('navbar-v3-current-user-id').textContent),
  userStates: JSON.parse(document.getElementById('navbar-v3-user-states').textContent),
  isAuthenticated: JSON.parse(document.getElementById('navbar-v3-is-authenticated').textContent),
  featureWhitelistedCollections: document.getElementById('feature-whitelist-collections')
    ? JSON.parse(document.getElementById('feature-whitelist-collections').textContent)
    : false,
  isCreator: JSON.parse(document.getElementById('navbar-v3-is-creator').textContent),
  canDefer: JSON.parse(document.getElementById('navbar-v3-can-defer').textContent),
  fiatEnabled: JSON.parse(document.getElementById('navbar-v3-fiat-enabled').textContent),
  fullName: JSON.parse(document.getElementById('navbar-v3-full-name').textContent),
  landingUrl: JSON.parse(document.getElementById('navbar-v3-landing-url').textContent),
  collectionUrl: JSON.parse(document.getElementById('navbar-v3-collection-url').textContent),
  likesUrl: JSON.parse(document.getElementById('navbar-v3-likes-url').textContent),
  displayProfileImageUrl: JSON.parse(
    document.getElementById('navbar-v3-display-profile-image-url').textContent
  ),
  isAutoCompleteEnabled: JSON.parse(
    document.getElementById('navbar-v3-is-autocomplete-enabled').textContent
  ),
  isProductView: JSON.parse(document.getElementById('navbar-v3-is-product-view').textContent),
};

// Targets for global search bar overlay
export const REACT_OVERLAY_TARGET_CLASSNAME = 'react_content_main';
export const REACT_OVERLAY_TARGET_CLASSNAME_PLUS_PERIOD = `.${REACT_OVERLAY_TARGET_CLASSNAME}`;
export const DJANGO_OVERLAY_TARGET_CLASSNAME_PLUS_PERIOD = '.page_content_main';
export const DJANGO_BANNER_TARGET_CLASSNAME_PLUS_PERIOD = '.top-banner';

export const CURRENCY_SYMBOLS = {
  FIAT: { USD: '$' },
  CRYPTO: {
    ETH: 'Ξ',
  },
};

export const ACTION_TRACKING_EVENT_TYPE = {
  MARKET_PLACE_SEARCH_QUERY: 'marketplace:query',
  MARKET_PLACE_SEARCH_QUERY_PRICE_RANGE_APPLIED: 'marketplace:query:pricerange:applied',
  MARKET_PLACE_SEARCH_QUERY_FILTERS: 'marketplace:query:filters', // + filter name + filter value
  PRODUCT_SETTINGS: {
    OPEN_MODAL: 'product-settings:open-modal:', // + modal name
    CLOSE_MODAL: 'product-settings:close-modal:', // + modal name
    TOGGLED_ON: 'product-settings:toggled:on',
    TOGGLED_OFF: 'product-settings:toggled:off',
    PRICE_TOUCHED: 'product-settings:price-touched',
    CANCEL_SALE_SUCCEED: 'product-settings:transaction:cancel-sale:success',
    CANCEL_SALE_FAILED: 'product-settings:transaction:cancel-sale:failed',
    DELIST_CONFIRMED: 'product-settings:delist:confirmed',
    DELIST_SUCCEED: 'product-settings:delist:succeed',
    DELIST_FAILED: 'product-settings:delist:failed',
    CREATE_SALE_SUCCEED: 'product-settings:transaction:create-sale:succeed',
    CREATE_SALE_FAILED: 'product-settings:transaction:create-sale:failed',
    SALE_VALIDATED: 'product-settings:sale-validated',
    INVALID_SALE: 'product-settings:invalid-sale',
    LISTING_CONFIMRED: 'product-settings:listing:confirmed',
    LISTING_SUCCEED: 'product-settings:listing:succeed', // created sale offchain/reserve price set
    LISTING_FAILED: 'product-settings:listing:failed',
    GIVE_APPROVAL_INTENT: 'product-settings:give-approval-intent',
  },
};

export const PRODUCT_SALE_STATUS_ENUM = {
  IS_DISABLED: 'IS_DISABLED',
  METAMASK_PROCESSING: 'METAMASK_PROCESSING',
  METAMASK_INSTALL_REQUIRED: 'METAMASK_INSTALL_REQUIRED',
  METAMASK_NETWORK_MISMATCH: 'METAMASK_NETWORK_MISMATCH',
  METAMASK_LOGIN_REQUIRED: 'METAMASK_LOGIN_REQUIRED',
  METAMASK_APPROVAL_REQUIRED: 'METAMASK_APPROVAL_REQUIRED',
  METAMASK_ADDRESS_MISMATCH: 'METAMASK_ADDRESS_MISMATCH',
  SALE_CONTRACT_APPROVAL_REQUIRED: 'SALE_CONTRACT_APPROVAL_REQUIRED',
  SALE_CONTRACT_APPROVAL_PROCESSING: 'SALE_CONTRACT_APPROVAL_PROCESSING',
  IS_PENDING_SALE: 'IS_PENDING_SALE',
  IS_AUCTION_PENDING_SALE: 'IS_AUCTION_PENDING_SALE',
  HAS_AUCTION_COUNTDOWN: 'HAS_AUCTION_COUNTDOWN',
  DELISTING_IN_PROGRESS: 'DELISTING_IN_PROGRESS',
  IS_AVAILABLE_FOR_SALE_AND_HAD_PRICE: 'IS_AVAILABLE_FOR_SALE_AND_HAD_PRICE',
  IS_AVAILABLE_FOR_SALE: 'IS_AVAILABLE_FOR_SALE',
  HAS_PENDING_TRANSACTION: 'HAS_PENDING_TRANSACTION',
  LISTING_UPGRADE_REQUIRED: 'LISTING_UPGRADE_REQUIRED',
  EXISTING_SALE_ONCHAIN: 'EXISTING_SALE_ONCHAIN',
  INACTIVE: 'INACTIVE',
};

// Matching with EthereumTransactionStateEnum
export const ETH_TX_STATUS_ENUM = {
  POSTED: 1,
  MINED: 2,
  CANCELLED: 3,
  PENDING: 4,
  FAILED: 5,
  TRYING: 6,
};

// Matching with TransactionObjectTypeEnum
export const ETH_TX_OBJECT_TYPE = {
  RELEASE: 1,
  SALE: 2,
  ETH_BID: 3,
  CC_BID: 4,
  SEND: 5,
  BURN_REQUEST: 6,
  PRODUCT_DEPOSIT: 7,
  UPDATE_METADATA: 8,
  MEDIA_PAYOUT_REQUEST: 9,
  ETH_MEDIA_BID: 10,
  CC_MEDIA_BID: 11,
};

export const PRICE_TYPE = {
  BUY_NOW: 'buy_now',
  RESERVE_PRICE: 'reserve_price',
};

// new nagbar placement name need to be add to this constant
export const NAGBAR_PLACEMENT_PAGE = {
  DESKTOP: {
    '/': 'HomeNavbar',
    // TODO: Explore Artwork, figure out nagbar interaction outside of legacy Marketplace
    '/marketplace/': 'MarketplaceNavbar',
  },
  // if there's no mobile match, we default to desktop,
  // this also use to avoid query for mobile version on mobile autimatically
  MOBILE: {
    '/': 'HomeNavbarMobile',
    // TODO: Explore Artwork, figure out nagbar interaction outside of legacy Marketplace
    '/marketplace/': 'MarketplaceNavbarMobile',
  },
};

export const THEME_MODE = {
  DARK: 'dark',
  DEFAULT: 'default',
};

export const MINIMUM_SALE_PRICE_IN_USD = 112; // 0.035ETH in USD
export const MINIMUM_SALE_PRICE_IN_ETH = 0.035;
export const DISABLE_SEND_PERIOD = 72;
