import { memo, createContext, ReactNode } from 'react';

export interface TrackCTAContext {
  'data-cta-module'?: string;
  'data-cta-detail'?: string;
}

type TrackCTAContextProviderParams = {
  children: ReactNode;
  module: NonNullable<TrackCTAContext['data-cta-module']>;
  detail: NonNullable<TrackCTAContext['data-cta-detail']>;
};

const TrackCTAContext = createContext<TrackCTAContext>({});

export const TrackCTAContextProvider = memo(function TrackCTAContextProvider({
  children,
  module,
  detail,
}: TrackCTAContextProviderParams) {
  return (
    <TrackCTAContext.Provider
      value={{ 'data-cta-module': module, 'data-cta-detail': detail || '' }}
    >
      {children}
    </TrackCTAContext.Provider>
  );
});

export default TrackCTAContext;
